<template>
  <div class="content md flex-box vertical">
    <div class="buddha-temp-loading" v-if="loading">
      <a-spin tip="加载中" />
    </div>
    <template v-else>
      <h3 class="buddha-add-title">选择佛事/法会登记</h3>
      <div class="flex-grow scroll-area">
        <div class="buddha-type-select">
          <a-card class="buddha-cat-area" :title="cat.title" v-for="cat in catList" :key="cat.key">
            <div class="buddha-cat-types">
              <div class="buddha-type-item" @click="selectType(t)" v-for="t in cat.list" :key="t.id">
                <div class="buddha-type-icon"
                     :style="{backgroundImage: `url(${require('../../assets/buddha/fs_'+ t.fs_type + '.png')})`}"></div>
                <div class="buddha-type-name">{{t.name}}</div>
              </div>
            </div>
          </a-card>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
    import {buddhaCategoryList, buddhaTypeList} from "../../common/buddha/type";
    export default {
        name: "BuddhismAdd",
        data() {
            return {
                catList: [],
                loading: false,
            }
        },
        created() {
            this.getTempList();
        },
        methods: {
            selectType(t) {
                this.$store.commit("setBuddhaTemp", t);
                this.$router.push({name: t.cat === 3 ? 'DharmaAssembly' : 'BuddhismRegister', params: {id: t.id}});
            },
            getTempList() {
                this.loading = true;
                const url = `/admin/fs-temp?sort=fs_type&pageSize=99&expand=common&filter[cat][neq]=2`;
                this.$axios(url).then(res => {
                    const list = res.data;
                    const catList = buddhaCategoryList.filter(c => c.key !== 2);
                    catList.forEach(c => {
                        c.list = list.filter(t => t.cat == c.key).filter(t => buddhaTypeList.find(b => b.key == t.fs_type)?.subTypes?.length > 0);
                    });
                    this.catList = catList;
                }).finally(() => {
                    this.loading = false;
                });
            },
        }
    }
</script>

<style scoped lang="less">
  .buddha-temp-loading {
    margin-top: 100px;
    text-align: center;
  }
  .buddha-add-title {
    position: relative;
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: var(--border);
    font-size: 18px;
    text-align: center;
  }
  .buddha-cat-area {
    &:not(:first-of-type) {
      margin-top: 20px;
    }
  }

  .buddha-cat-types {
    display: flex;
  }

  .buddha-type-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0;
    width: 20%;
    cursor: pointer;

    &:hover {
      background-color: @background-color-light;
    }
  }

  .buddha-type-icon {
    width: 48px;
    height: 48px;
    background: center no-repeat;
    background-size: contain;
  }

  .buddha-type-name {
    margin-top: 10px;
    font-size: 16px;
  }

  .buddha-add-form {
    padding: 10px 30px;
  }
  .add-success-title {
    margin: 40px 0;
    font-size: 24px;
    text-align: center;
  }
  .buddhism-qr {
    margin: 0 auto;
    width: 300px;
    height: 300px;
  }
  .buddhism-qr-img {
    width: 100%;
    height: 100%;
  }
  .buddhism-button {
    display: flex;
    justify-content: space-between;
    margin: 20px auto;
    width: 300px;
  }
</style>
